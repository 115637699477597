import React, { useState } from 'react';
import '../App.css';

const FormDiv = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here
    console.log('Form submitted!');
    console.log('Name:', name);
    console.log('Email:', email);
    console.log('Phone:', phone);
    console.log('Message:', message);
    // Reset form fields
    setName('');
    setEmail('');
    setPhone('');
    setMessage('');
  };

  return (
    < div className='form-padding'>
   
      <form onSubmit={handleSubmit}>
        <div className='name-input'>
          <label htmlFor="name">Name:</label><br></br>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>

        <div className='form-flex'> 
        <div className='email-input'> 
          <label htmlFor="email">Email:</label><br></br>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='number-input'>
          <label htmlFor="phone">Phone Number:</label><br></br>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
        </div>
        </div>
        <div className='message-input'>
          <label htmlFor="message">Message:</label><br></br>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default FormDiv;
