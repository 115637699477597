
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';

import Homepage from "./pages/Homepage";
import Signup from "./pages/Signup";


function App() {



  return (

    <div>
    


     

         <Routes >
              <Route path="/" element={<Homepage />}/>
              <Route path="Signup" element={<Signup />}/>
        </Routes>
    
           
    </div>
  );
}

export default App;
