import React, { useState, useEffect } from 'react';
import clientsays1 from '../images/clientsays1.PNG'; 
import clientsays2 from '../images/clientsays2.PNG'; 
import clientsays3 from '../images/clientsays3.PNG'; 

const DivSlider = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [slides.length]);

  return (
    <div className="slider">
      <div
        className="slide-container"
        style={{ transform: `translateX(-${currentSlide * 600}px)` }}
      >
        <div className={`slide ${currentSlide === 0 ? 'active' : ''}`}>
                                                  
                                                            <img src={clientsays1} alt="Logo" className="" /> 
                                                          
                                                     
        </div>
        <div className={`slide ${currentSlide === 1 ? 'active' : ''}`}>
                                                         <img src={clientsays2} alt="Logo" className="" /> 
        </div>
        <div className={`slide ${currentSlide === 2 ? 'active' : ''}`}>
                                                      <img src={clientsays3} alt="Logo" className="" />                                  
         </div>
        
      </div>
    </div>
  );
};

export default DivSlider;

