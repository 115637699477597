import React from 'react';
import { FaBasketballBall, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa';

const Footer = () => {
  return (
    <div>
       <div className='container'>
        <div className='margin-auto'>
               <div className='social-medias'>
                     <FaFacebook />
                     <FaTwitter />
                    <FaLinkedin />
                    <FaBasketballBall />
                    

               </div>
               <div className='copyright'>
                <p>© E-Solution Technology. All Rights Reserved. Powered by E-Solution </p>
               </div>
        </div>
       </div>
    </div>
  )
}

export default Footer
