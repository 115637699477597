import React from 'react'
 import FormDiv from './FormDiv'


const Sectioneight = () => {
  return (
    < div className='blue-bg' id='formfiling'>
      <div className='container'>
        <div className='form-section'>
            
            <p>LET'S WORK TOGETHER</p>
            <h3>Tell us about your project</h3>
         <div className='form'>
           <FormDiv />
             
         </div>
      </div>
      </div>
    </div>
  )
}

export default Sectioneight
