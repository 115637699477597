import React , { useEffect, useState }  from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

import '../App.css';

import SectionFour from '../components/SectionFour';
import SectionThree from '../components/SectionThree';
import Sectiontwo from '../components/Sectiontwo';
import SectionFive from '../components/SectionFive';
import SectionSix from '../components/SectionSix';
import SectionSeven from '../components/SectionSeven';
import Sectioneight from '../components/Sectioneight';
import Sectionnine from '../components/Sectionnine';
import Footer from '../components/Footer';
import ScrollAnimation from '../components/ScrollAnimation';
import NavBar from '../components/NavBar';


const Homepage = () => {
  return (
    <div>
         <NavBar />
          <Sectiontwo />

           <SectionThree />
          <SectionFour />
        <SectionFive />
         <SectionSix /> 
         <SectionSeven />
         <Sectioneight />   
          <Sectionnine />   
          <Footer />
           
    </div>
  )
}

export default Homepage;
