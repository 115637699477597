import React from 'react'
import '../App.css';
import skills from '../images/mobile-app.svg'; 
import game from '../images/joystick.svg'; 
import  ecommerce from '../images/smartphone.svg'; 
import finance from '../images/money-growth.svg'; 
import healthcare from '../images/healthcare.svg'; 
import resturant from '../images/baker.svg'; 
import estate from '../images/house.svg'; 
import tour from '../images/travel-case.svg'; 
import education from '../images/video-tutorials.svg'; 
import transport from '../images/taxi.svg'; 
import eventa from '../images/event.svg'; 
import grocery from '../images/groceries.svg'; 




const SectionSix = () => {
  return (
    <div className='serviceSection' id='services'>

    <div className='container'>
        <div className='industries'>

            <h2>Industries We Serve</h2>
            <p>Successfully delivered digital products</p>
           
        <div className='grid-4by3'>
                 <div className='icon-card'>
                 <img src={skills} alt="Logo" className="" />
                 <h5>On-Demand</h5>
                 </div>

                 <div className='icon-card'>
                 <img src={game} alt="Logo" className="" />
                 <h5>Game</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={ecommerce} alt="Logo" className="" />
                 <h5>eCommerce</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={finance} alt="Logo" className="" />
                 <h5>Finance</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={healthcare} alt="Logo" className="" />
                 <h5>Healthcare</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={resturant} alt="Logo" className="" />
                 <h5>Restaurant</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={estate} alt="Logo" className="" />
                 <h5>Real estate</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={tour} alt="Logo" className="" />
                 <h5>Tour & Travels</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={education} alt="Logo" className="" />
                 <h5>Education</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={transport} alt="Logo" className="" />
                 <h5>Transport</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={eventa} alt="Logo" className="" />
                 <h5>Event</h5>
                 </div>
                 <div className='icon-card'>
                 <img src={grocery} alt="Logo" className="" />
                 <h5>Grocery</h5>
                 </div>






        </div>

        </div>
        </div>
      
    </div>
  )
}

export default SectionSix
