import React, { useEffect, useRef } from 'react';

const CountingComponentpercent = () => {
  const countingRef = useRef(null);

  useEffect(() => {
    const countingElement = countingRef.current;
    let count = 0;

    const countingInterval = setInterval(() => {
      count++;
      countingElement.textContent = `${count}%`;

      if (count === 100) {
        clearInterval(countingInterval);
      }
    }, 10);

    return () => clearInterval(countingInterval);
  }, []);

  return (
    <div className="counting-component" ref={countingRef}>
      0%
    </div>
  );
};

export default CountingComponentpercent;
