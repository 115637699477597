import React from 'react'

import computer from '../images/computer.PNG'; 
import computer2 from '../images/computer2.PNG'; 
import computer3 from '../images/computer3.PNG'; 
import whychoose from '../images/why-choose.png'; 
import { FaGreaterThan } from 'react-icons/fa';
import CallButton from './CallButton';

const SectionFour = () => {
  return (
    <div className='sectionfour-bg'>
         <div className='container'>
            <div className='grid-parent-two'>
                  <div className='grid-child-50percent'>
                    <h3>Here’s why people <span>choose </span>  to work with  <span>E-solutions</span> </h3>
                    <p> We treat you like a partner and build on your vision by showing you new possibilities and alternative that suit you better.</p>
                      <div className='straight-grid'>
                        <div className='flex-20-80'>
                              <div className='flex-20'>
                              <img src={computer} alt="Logo" className="" />
                              </div>
                              <div className='flex-80'>

                                <h4> Brainstorming and Ideation</h4>
                                 <p> We build and strategize solutions based on your vision so that you have all the options before moving forward with your project.</p>

                              </div>
                        </div>

                        <div className='flex-20-80'>
                              <div className='flex-20'>
                              <img src={computer2} alt="Logo" className="" />
                              </div>
                              <div className='flex-80'>

                                <h4>100% satisfaction guaranteed</h4>
                                 <p>Your satisfaction is our very first priority. If you have any concern about your game, we are here to solve them in every possible manner with flexibility. </p>

                              </div>
                        </div>

                        <div className='flex-20-80'>
                              <div className='flex-20'>
                              <img src={computer3} alt="Logo" className="" />
                              </div>
                              <div className='flex-80'>

                                       <h4>24/7 communication</h4>
                                 <p> We are always there for you through Email, Call, or Skype.</p>

                              </div>
                        </div>
                      </div>
                
                  </div>

                  <div className='grid-child-50percent'> 
                       <img src={whychoose} alt="Logo" className="why-chooseimg  skewy-div" />
                       <div className='why-choose-text'>
                        <p>Let's build a solution that fulfills your dream. With an experienced and talented team, you can build precisely what you need.</p>
                        <h3>Start your <span> project </span>right away!</h3>
                        <p> Time is of the essence. Let's talk today and see what we can do with your vision.</p>
                        <CallButton phoneNumber="+2347032647894" />
                       </div>
                  </div>
            </div>
         </div>
    </div>
  )
}

export default SectionFour
