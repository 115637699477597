import React, { useState } from 'react';
import { RiEyeLine, RiEyeOffLine } from 'react-icons/ri';
import '../App.css';
import logo from '../images/logo3-fotor-bg-remover-2023061011249.png';
import { Link } from 'react-router-dom';

const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form submission logic here
    console.log('Form submitted:', formData);
    // Clear form fields
    setFormData({ name: '', email: '', password: '' });
  };

  return (
    <div className="signup-container">
        <img src={logo} alt="Logo" className="" />
      <h2>SIGN IN</h2>
      <form className="signup-form" onSubmit={handleSubmit}>
        {/* <div className="form-group">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Enter your name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div> */}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              name="password"
              placeholder="Enter your password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <div className="password-toggle" onClick={togglePasswordVisibility}>
              {showPassword ? <RiEyeOffLine /> : <RiEyeLine />}
            </div>
          </div>
        </div>
        <p> <a> Forgot password? </a></p>
        <button type="submit">  Continue</button>
        <span><Link to="/"> Don't have an account? </Link></span>
        <span className='register'> <Link to="/">REGISTER HERE </Link></span>
      </form>
    </div>
  );
};

export default Signup;
