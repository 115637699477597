import React from 'react'

const SectionThree = () => {
  return (

    <div className='serviceSection'>
    <div className='container'>
            
            < div className='ourservices'>
              <h2>Our Services</h2>
               <p>We think big and have hands in all leading technology platforms to provide you wide array of services.</p>
            </div>
         <div className='boxgrid'>
                        <div className='box-grid'>
                          <h4>iOS Development</h4>
                          <h5>SWIFT | REALM | COREDATA | OBJECTIVE C</h5>
                          <p>Build powerful apps for the iOS platform covering a unique
                              user base.</p>
                        </div>
                            <div className='box-grid'>
                              <h4>Android Development</h4>
                              <h5>JAVA | SQLITE | FIREBASE  | ROOM</h5>
                              <p>Create robust solutions for each industry across a vast
                                  user base</p>
                            </div>
                              <div className='box-grid'>
                                <h4>Game Development</h4>
                                <h5>BLENDER | MAYA | MAX | UNITY</h5>
                                <p>We build spectacular 2D and 3D multiplayer games for mobile devices powered by addictive gameplay.</p>
                              </div>

         </div>
    </div>
    </div>
  )
}

export default SectionThree