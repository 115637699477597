import React from 'react';
import '../App.css';
import suzuki from '../images/p1.png'; 
import artizone from '../images/p2.png'; 
import  canon from '../images/p4.png'; 
import unilever from '../images/p5.png'; 
import dreamworks from '../images/p3.png'; 
import estee from '../images/p6.png'; 
import politico from '../images/politico.png'; 
import sapient from '../images/sapient-logo-1.png'; 
import tissot from '../images/tissot-logo-1.png'; 
import walmart from '../images/walmart.png'; 
import whitewhistle from '../images/whitecastle-logo.png'; 
import datastream from '../images/datastream.png'; 




const SectionFive = () => {
  return (
    <div className='serviceSection'>

    <div className='container'>
        <div className='our-clients'>

            <h2> Some of our Partners</h2>
           
        <div className='grid-6by2'>
                 <div className='logo-card'>
                 <img src={suzuki} alt="Logo" className="" />
                 
                 </div>

                 <div className='logo-card'>
                 <img src={artizone} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={canon} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={unilever} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={dreamworks} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={estee} alt="Logo" className="" />
                 </div>
                 {/* <div className='logo-card'>
                 <img src={politico} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={sapient} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={tissot} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={walmart} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={whitewhistle} alt="Logo" className="" />
                 </div>
                 <div className='logo-card'>
                 <img src={datastream} alt="Logo" className="" />
                 </div> */}






        </div>

        </div>
        </div>
      
    </div>
  )
}

export default SectionFive
