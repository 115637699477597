import React from 'react'
import uk from '../images/west-palm-beach.png'; 
import nigeria from '../images/dubai-office.png'; 
import australia from '../images/karachi-office.png'; 

const Sectionnine = () => {
  return (
    < div className='' id='contact'>
<div className='container'>
      <div className='our-office'>
        <h2>Our Office</h2>
        <div className='contact-flex'>
            <h3> info@esolutiontec.com</h3>
            <h3>+2347032647894</h3>
        </div>

        <div className='address-grid'>
            <div className='address-box'>
                 <img src={uk} alt="Logo" className="" />
                 <div className='address-text'>
                 <h4>Nigeria</h4>
                      <p>c/o Suite 2-4, 5th Floor, Block B, Lister House, M.K.O. Abiola Way, Ring Road, Ibadan, Oyo State, Nigeria</p>
                 </div>
            </div>

            <div className='address-box'>
                 <img src={nigeria} alt="Logo" className="" />
                 <div className='address-text'>
                      <h4>Nigeria</h4>
                      <p>72B, Ireakari estate, 

                              Oluyole Extension, 

                              Akala way, Ibadan, Oyo State, Nigeria </p>
                 </div>
            </div>

            <div className='address-box'>
                 <img src={australia} alt="Logo" className="" />
                 <div className='address-text'>
                 <h4>Nigeria</h4>
                      <p>c/o Suite 2-4, 5th Floor, Block B, Lister House, M.K.O. Abiola Way, Ring Road, Ibadan, Oyo State, Nigeria</p>
                 </div>
            </div>
        </div>
      </div>

</div>      
    </div>
  )
}

export default Sectionnine
