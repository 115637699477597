import React , { useEffect, useState }  from 'react'
import herobanner from '../images/hero-banner1.png'; 
import { FaBars, FaGreaterThan, FaPhone, FaPhoneAlt } from 'react-icons/fa';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import elogo from '../images/logo3-fotor-bg-remover-2023061011249.png';
import { Link } from 'react-router-dom';
import signup from '../pages/Signup';
// import BadgeSlider from './BadgeSlider';


const NavBar = () => {
  const [showDiv, setShowDiv] = useState(false);



  const [navbarBackground, setNavbarBackground] = useState('transparent');
  const [navbarTextColor, setNavbarTextColor] = useState('white'); // Initial font color

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const navbarHeight = document.getElementById('navbar').offsetHeight;

      if (scrollPosition > navbarHeight) {
        setNavbarBackground('white'); // Change to the desired background color
        setNavbarTextColor('#280071'); // Change to the desired font color
      } else {
        setNavbarBackground('transparent');
        setNavbarTextColor('white');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  
  const handleClick = () => {
    setShowDiv(!showDiv);
  };



  return (
    <div>
    <div className='blue_background'>
                          
                       
        <nav className="navbar" 
                         id="navbar"
                         style={{
                           background: navbarBackground,
                           color: navbarTextColor,
                           transition: 'background 0.9s ease, color 0.5s ease' // CSS transition properties
                         }}
                         >
                       <div className="navbar__logo">  <a href='http://esolutiontec.com/'> <img src={elogo} alt="Logo" className="" /> </a></div>

                                 
                                      <ul className="navbar__menu" style={{color: navbarTextColor}}>
                                     <li className="navbar__menu-item" style={{color: navbarTextColor}}> <a href='#services'>SERVICES  </a> </li>
                                     <li className="navbar__menu-item" style={{color: navbarTextColor}}>  <a href="http://esolutiontec.com/webmail">  STAFF EMAIL  </a> </li>
                                     <li className="navbar__menu-item" style={{color: navbarTextColor}}><Link to="/Signup" >CLIENT'S LOGIN </Link> </li>
                                     <li className="navbar__menu-item" style={{color: navbarTextColor}}> <a  href="https://esolutiontng.atlassian.net">STAFF WORKPLACE </a></li>
                                     <li className="navbar__menu-item" style={{color: navbarTextColor}}> <a href='#contact'> CONTACT  </a></li>
                                     <li className="navbar__menu-item" style={{color: navbarTextColor}}> <a><FaPhoneAlt /> +2347032647894 </a> </li>
                                     <li className="navbar__menu-item" >  <a href='#formfiling'> <button className="navbar__button">Request A Quote</button>  </a></li>

                                 </ul>

                                 {/* <button className="navbar-toggle" onClick={toggleNavbar}>
                                    
                                    <div className={`navbar-icon ${expanded ? 'open' : ''}`}></div>
                                    <div className={`navbar-icon ${expanded ? 'open' : ''}`}></div>
                                    <div className={`navbar-icon ${expanded ? 'open' : ''}`}></div>
                                  </button>  */}

                              <button className="navbar-toggle" onClick={handleClick}>
                                <FaBars />
                              </button>
                          
                                
                         </nav>

                         {showDiv && <div className="expanded">

                         <ul className='navbar_menu'>
                                     <li className="navbar_menu-item" > <a href='#services'> SERVICES </a></li>
                                     <li className="navbar_menu-item"> <a href="http://esolutiontec.com/webmail">  STAFF EMAIL  </a></li>
                                     <li className="navbar_menu-item"><Link to="/Signup" >CLIENT'S LOGIN </Link> </li>
                                     <li className="navbar_menu-item"><a  href="https://esolutiontng.atlassian.net">STAFF WORKPLACE </a></li>
                                     <li className="navbar_menu-item"> <a href='#contact'>  CONTACT </a></li>
                                     <li className="navbar_menu-item"> <FaPhoneAlt />+2347035613209</li>
                                     <li className="navbar_menu-item" >  <a href='#formfiling'> <button className="navbar__button">Request A Quote</button> </a></li>

                                 </ul>
                         </div> }
                      
                                         
 

            <div className='container'>
                <div className='hero_parent_grid'>
                    <div className='hero_text'>
                        <h1>Top Information Technology Company</h1>
                        <h3>We deliver mobile app experiences that speaks volume</h3>
                       <a href='#formfiling'> <button>GET STARTED <FaGreaterThan /> </button>  </a>
                        {/* <h5>Our Accomplishments As An App Development Company</h5> */}
                        {/* <BadgeSlider /> */}

                    </div>
                    <div className='margintop'>
                       <div className='hero_section'>
                          <img src={herobanner} alt="Logo" className="hero_section" />
                       </div>
                    </div>

                </div>
               
        </div> 
    </div>
    </div>
  )
}

export default NavBar;
