import React from 'react'
import review1 from '../images/reviews-icon-3.png'
import review2 from '../images/reviews-icon-2.png'
import review3 from '../images/reviews-icon-1.png'
import DivSlider from './DivSlider'

const SectionSeven = () => {

    const slides = ['Slide 1', 'Slide 2', 'Slide 3'];
  return (
    <div>
      <div className='container'>
        <div className='flex-40-60'> 
                  <div className='flex-40'> 
                            <h5>WHAT OUR CLIENTS SAY ABOUT Esolutions.</h5>
                            <h2>Over 280+ Satisfied Clients and Growing</h2>
                            {/* <h4>Read More Reviews</h4> */}
                                {/* <div className='reviews-logo'>
                                        <div>
                                        <img src={review1} alt="Logo" className="" />
                                        </div>
                                        <div>
                                        <img src={review2} alt="Logo" className="" />
                                        </div>
                                        <div>
                                        <img src={review3} alt="Logo" className="" />
                                        </div>
                                </div> */}
                  </div>

                    
                  <div className='flex-60'>

                                <div className='slider'>
                                  <DivSlider slides={slides} />

                                </div>
                                </div>




        </div>
      </div>
    </div>
  )
}

export default SectionSeven
