import React from 'react';
import sectiontwoimg from '../images/sectionsTwoImg.png'; 
import sectiontwoimg2 from '../images/sectionsTwoImg2.png'; 
import sectiontwoimg3 from '../images/sectionsTwoImg3.png'; 
import sectiontwoimg4 from '../images/sectionsTwoImg4.png'; 
import sectiontwoimg5 from '../images/sectionsTwoImg5.png'; 
import sectiontwoimg6 from '../images/sectionsTwoImg6.png'; 
import CountingComponentpercent from './CountingComponentpercent';
import CountYears from './CountYears';
import CountSquad from './CountSquad';
import CountApps from './CountApps';
import CountCountry from './CountCountry';

const Sectiontwo = () => {
  return (
    <div className='bg-light'>
    <div className='container'>
      <div className='sectiontwogridrow'>


             <div className='sectiontwogridcolumns'>
             <div> <h1> <span >Experience </span> World-class Agile Product Development</h1>
                        <p> Harness digitized business solutions comprising web, android and iOS app solutions leveraging Blockchain,
                          AI Chatbots, Machine Learning and IoT technologies for your startup or enterprise.</p> 

                         <h5>Build and deploy thriving agile solutions that help you design, develop, and scale. </h5>
                        
                        
                        
                </div>


                     <div>
                             <h1> Let's Start a New Project Together  </h1>

                            <button className='sectiontwobtn'>Request A Quote</button>
                    </div>
              </div>

      <div className='sectiontwogridcolumns2'>
          <div className='sectiontewogridrows3'>
                        <div className='section2card'>
                        <img src={sectiontwoimg} alt="Logo" className="" />
                        <h3> <CountYears /> +</h3>
                        <p>Years </p>
                        <p>Experience</p>
                        </div>
                            <div className='section2card'>
                            <img src={sectiontwoimg2} alt="Logo" className="" />
                            <h3> <CountSquad /> +</h3>
                            <p> Talented</p>
                            <p>Squad</p>
                            </div>
                                <div className='section2card'>
                                <img src={sectiontwoimg3} alt="Logo" className="" />
                                <h3> <CountApps />  +</h3>
                                <p> Apps</p>
                                <p>Developed</p>
                                </div>
          </div>
          <div className='sectiontewogridrows3'>
                            <div className='section2card'>
                                <img src={sectiontwoimg4} alt="Logo" className="" />
                                <h3> <CountingComponentpercent /> </h3>
                                <p> Projects</p>
                                <p>Delivered</p>
                            </div>
                                <div className='section2card'>
                                    <img src={sectiontwoimg5} alt="Logo" className="" />
                                    <h3> <CountCountry /> +</h3>
                                    <p> Countries</p>
                                    <p>Served</p>
                                </div>
                                    <div className='section2card'>
                                        <img src={sectiontwoimg6} alt="Logo" className="" />
                                        <h3> <CountingComponentpercent /></h3>
                                        <p>Client </p>
                                        <p>Satisfaction</p>
                                    </div>
          </div>

        </div>




    </div>
    </div>
    </div>
  )
}

export default Sectiontwo;
