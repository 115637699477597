import React from 'react';
import { FaGreaterThan } from 'react-icons/fa';

const CallButton = ({ phoneNumber }) => {
  const handleCall = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const telUrl = `tel:${phoneNumber}`;

    if (isMobile) {
      window.location.href = telUrl; // Initiates the phone call on mobile
    } else {
      const isCallAppAvailable = Boolean(navigator.userAgent.match(/(iPod|iPhone|iPad|Android)/i));

      if (isCallAppAvailable) {
        window.open(telUrl); // Prompts to open the default calling app on desktop
      } else {
        // Display an error message or provide an alternative action for desktop
        console.log('Cannot make a call on this device.');
      }
    }
  };

  return (
    <button onClick={handleCall}>Call now! <FaGreaterThan /> </button>
  );
};

export default CallButton;
